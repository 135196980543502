



































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import WebinarDetailsUpdateForm from "@/components/forms/Webinars/WebinarUpdate/WebinarDetailsUpdateForm.vue";
import WebinarDetailsFormModel from "@/data/from/WebinarDetailsFormModel";
import { buildWebinarUpdateDetailsRequest } from "@/Utils/buildWebinarCreateRequest";
import { ApiException } from "@/clients/clients";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";

export default Vue.extend({
  name: "WebinarEditDetails",
  components: {
    AppPage,
    AppPageBanner,
    AppPageLoading,
    WebinarDetailsUpdateForm,
  },
  props: {
    id: String,
  },
  data: () => ({
    loading: true,
    error: false,
    message: "",
  }),
  computed: {
    ...mapGetters("Webinar", ["webinar", "settings"]),
    ...mapState(["assetsBaseUrl"]),
  },
  async created() {
    if (!this.id) {
      await this.$router.push("/webinars");
      return;
    }
    await this.getWebinar(this.id);
    this.loading = false;
  },
  methods: {
    ...mapActions("Webinar", [
      "getWebinar",
      "getSettings",
      "updateWebinarDetails",
      "reset",
    ]),
    async onSubmit(data: WebinarDetailsFormModel) {
      this.loading = true;
      this.error = false;
      try {
        const request = await buildWebinarUpdateDetailsRequest(data, this.id);
        const response = await this.updateWebinarDetails(request);
        await this.$router.push(`/webinars/${response.id}`);
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.error = true;
        this.message = `An error occurred: ${(e as ApiException).message}`;
      }
    },
  },
});
